import React from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { Td, TdCheckbox, Tr } from '@brainstud/ui/Static/Table';
import classNames from 'classnames/bind';
import { filterObjectTags, useObjectTags } from 'Hooks/useObjectTags';
import { CardTag } from 'Modules/action-based-learning-panel/Components/Cards/CardTag';
import { AnswerStatus } from 'Modules/blended-learning-catalog-panel/Components/AnswerStatus';
import { useRouter } from 'next/router';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './LearningObjectRow.module.css';

const cx = classNames.bind(styles);

export const LearningObjectRow = ({
  learningObject,
  learningObjectUrl,
}: {
  learningObject: LearningObject;
  learningObjectUrl: string;
}) => {
  const tags = useObjectTags(learningObject);
  const router = useRouter();
  const { profileId } = router.query as { profileId: string };

  return (
    <Tr key={learningObject.id} href={learningObjectUrl}>
      {!profileId && <TdCheckbox id={learningObject.id} />}
      <Td to={learningObjectUrl}>{learningObject?.title}</Td>
      <td>
        {learningObject.lastAnswer?.().status && (
          <AnswerStatus
            status={
              learningObject.lastAnswer?.().status as
                | 'TURNED_IN'
                | 'REJECTED'
                | 'ACCEPTED'
            }
          />
        )}
      </td>
      <td>
        {DateFormatter.toDateTime(learningObject.lastAnswer?.().updatedAt)}
      </td>
      <Td collapse>
        <div className={cx(styles.icons)}>
          {tags.filter(filterObjectTags).map((tag) => (
            <CardTag key={tag.name} type={tag.name} value={tag?.value} />
          ))}
        </div>
      </Td>
      <Td />
    </Tr>
  );
};
