import React, { useCallback, useMemo, useState } from 'react';
import { useExposition } from '@brainstud/academy-api/Hooks/useExpositions';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { Button } from '@brainstud/ui/Buttons/Button';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Checkbox } from '@brainstud/ui/Form Input/Checkbox';
import { Search } from '@brainstud/ui/Form Input/Search';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { Badge } from '@brainstud/ui/Static/Badge';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, Td, TdCheckbox, Th, Tr } from '@brainstud/ui/Static/Table';
import { useDataSelection } from '@brainstud/universal-components/Providers/DataProvider/useDataSelection';
import {
  Add,
  Close,
  DeleteForeverOutlined,
  UploadFile,
} from '@mui/icons-material';
import { ReactComponent as FolderImage } from 'Assets/folder-search.svg';
import classNames from 'classnames/bind';
import { LearningCard } from 'Components/Cards/LearningCard';
import { ObjectCard } from 'Components/Cards/ObjectCard';
import { ReactComponent as FallbackImage } from 'Components/Cards/ObjectCard/fallback-image.svg';
import { ErrorView } from 'Components/Errors/ErrorView';
import { ExpositionHeader } from 'Components/ExpositionHeader/ExpositionHeader';
import { FilterSidebarButton } from 'Components/FilterButtons';
import { GridListToggle } from 'Components/GridListToggle';
import { Link } from 'Components/Link';
import { Loading } from 'Components/Loading';
import { FEATURES } from 'Config/features';
import { usePortalFeatureCheck } from 'Hooks/PortalFeatureCheck/usePortalFeatureCheck';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { isDocument } from 'Utils/getFileType';
import { useProfileProvider } from 'Views/Profile/ProfileLayout/useProfileProvider';
import { SelectionMenu } from '../../../Components/SelectionMenu';
import { AnswersView } from '../AnswersView/AnswersView';
import { StoredFileActionMenu } from './StoredFileActions/StoredFileActionMenu';
import { LearningObjectRow } from './Table/LearningObjectRow';
import styles from './ExpositionsResourceView.module.css';

const cx = classNames.bind(styles);

export const ExpositionsResourceView = () => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [search, setSearch] = useState<string>('');
  const [isGridView, setIsGridView] = useState(true);
  const [isAddingObjects, setIsAddingObjects] = useState(false);
  const [addFiles, setAddFiles] = useState(false);
  const { baseUrl, headers: apiHeaders } = useApi();
  const router = useRouter();
  const isFileUploadEnabled = usePortalFeatureCheck(FEATURES.ACCOUNT_FILES);
  const { expositionId, profileId } = router.query as {
    expositionId: string;
    profileId: string;
  };

  const [{ data: exposition, update, createOrUpdate }, { isLoading, error }] =
    useExposition({
      exposition: expositionId,
      include: [
        'learning_objects',
        'learning_objects.course',
        'learning_objects.course.enrollment',
        'learning_objects.last_answer',
        'stored_files',
      ],
      showStoredFilesCount: isFileUploadEnabled,
      shared: !!profileId,
    });

  const { isMine } = useProfileProvider();
  const isExternal = router.pathname.startsWith('/profiles');

  const { selection, handleToggleSelectAll, handleToggleSelect } =
    useDataSelection();
  const [files, setFiles] = useState<string[]>([]);
  const courses = useMemo(
    () =>
      exposition
        ?.learningObjects?.()
        .reduce<
          Course[]
        >((prev, current) => [...prev, ...(current.course && !prev.find((item) => item.id === current?.course?.().id) ? [current.course()] : [])], []),
    [exposition]
  );

  const [courseFilters, setCourseFilters] = useState<string[]>([]);
  const handleToggleFilter = useCallback((courseId: string | undefined) => {
    if (courseId) {
      setCourseFilters((prevFilters) =>
        prevFilters.includes(courseId)
          ? prevFilters.filter((course) => course !== courseId)
          : [...prevFilters, courseId]
      );
    }
  }, []);

  useBreadcrumbs(
    {
      expositions: {
        href: profileId ? null : undefined,
      },
      ...(exposition && { [exposition.id]: exposition.title }),
    },
    [exposition, profileId]
  );

  const learningObjects = useMemo(
    () => exposition?.learningObjects?.() || [],
    [exposition]
  );

  const visibleLearningObjects = useMemo(() => {
    const searched = search
      ? learningObjects.filter((item) =>
          item.title.toLowerCase().includes(search.toLowerCase())
        )
      : learningObjects;

    return courseFilters.length > 0
      ? searched.filter((item) =>
          courseFilters.includes(item.course?.().id || '')
        )
      : searched;
  }, [search, learningObjects, courseFilters]);

  const visibleStoredFiles = useMemo(() => {
    const searched = search
      ? exposition
          ?.storedFiles?.()
          .filter((item) =>
            item.originalFileName.toLowerCase().includes(search.toLowerCase())
          ) || []
      : exposition?.storedFiles?.() || [];

    return courseFilters.length > 0 ? [] : searched;
  }, [courseFilters.length, search, exposition]);

  const handleAddLearningObjects = useCallback(() => {
    if (exposition && learningObjects) {
      update.mutate(
        {
          ...exposition,
          relationships: {
            learning_objects: [
              ...learningObjects.map((item) => item.id),
              ...selection,
            ],
          },
        },
        {
          onSuccess: () => {
            handleToggleSelectAll();
            setToast(
              t('views.portfolio.expositions.update.success'),
              'success'
            );
            setIsAddingObjects((prevAddingObjects) => !prevAddingObjects);
          },
          onError: () => {
            setToast(t('views.portfolio.expositions.update.error'), 'error');
          },
        }
      );
    }
  }, [
    exposition,
    learningObjects,
    update,
    selection,
    handleToggleSelectAll,
    setToast,
    t,
  ]);

  const handleRemoveLearningObjects = useCallback(() => {
    if (exposition && learningObjects) {
      update.mutate(
        {
          ...exposition,
          relationships: {
            learning_objects: learningObjects
              .filter((item) => !selection.includes(item.id))
              .map((item) => item.id),
          },
        },
        {
          onSuccess: () => {
            handleToggleSelectAll();
          },
        }
      );
    }
  }, [exposition, learningObjects, update, selection, handleToggleSelectAll]);

  const getLearningObjectUrl = useCallback(
    (learningObject) =>
      profileId
        ? `/profiles/${profileId}/expositions/${expositionId}/detail/${learningObject.id}`
        : `/portfolio/expositions/${expositionId}/detail/${learningObject.id}`,
    [expositionId, profileId]
  );

  const getStoredFileUrl = useCallback(
    (storedFile) =>
      profileId
        ? `/profiles/${profileId}/expositions/${expositionId}/files/${storedFile.id}`
        : `/portfolio/expositions/${expositionId}/files/${storedFile.id}`,
    [expositionId, profileId]
  );

  const handleFileUpload = useCallback(() => {
    if (exposition && isFileUploadEnabled) {
      const newData = {
        title: exposition.title,
        description: exposition.description,
        image: exposition?.image,
        relationships: {
          learning_objects:
            exposition
              .learningObjects?.()
              .map((learningObject) => learningObject.id) || [],
          stored_files: files,
        },
      };

      createOrUpdate.mutateAsync(newData, {
        onSuccess: () => {
          setToast(t('views.portfolio.expositions.update.success'), 'success');
          setFiles([]);
          setAddFiles(false);
        },
        onError: () => {
          setToast(t('views.portfolio.expositions.update.error'), 'error');
        },
      });
    }
  }, [exposition, isFileUploadEnabled, files, createOrUpdate, setToast, t]);

  if (error) {
    return <ErrorView />;
  }

  if (isExternal && !isMine && !profileId) {
    return (
      <div className={cx(styles.error)}>
        <ErrorView
          title={t('exceptions.UnauthenticatedException.title')}
          message={t('exceptions.UnauthenticatedException.description')}
        />
      </div>
    );
  }

  return (
    <div className={styles.base}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={cx(styles['learning-objects'])}>
          {exposition && <ExpositionHeader exposition={exposition} />}
          {!isExternal && (
            <div className={styles['actions-bar']}>
              {!isAddingObjects && (
                <Button
                  block
                  white
                  onClick={() => {
                    setIsAddingObjects(
                      (prevAddAssignments) => !prevAddAssignments
                    );
                    setAddFiles(false);
                  }}
                  className={cx(styles['add-toggle'])}
                >
                  <Add fontSize="large" />
                  <span>
                    {t('views.portfolio.expositions.detail.add_assignments')}
                  </span>
                </Button>
              )}
              {(isAddingObjects || addFiles) && (
                <Button
                  block
                  white
                  onClick={() => {
                    setIsAddingObjects(false);
                    setAddFiles(false);
                  }}
                  className={cx(styles['add-toggle'])}
                >
                  <Close fontSize="large" />
                  <span>
                    {t('views.portfolio.expositions.detail.show_contents')}
                  </span>
                </Button>
              )}
              {!addFiles && isFileUploadEnabled && (
                <Button
                  block
                  white
                  onClick={() => {
                    setIsAddingObjects(false);
                    setAddFiles((prevAddFiles) => !prevAddFiles);
                  }}
                  className={cx(styles['add-toggle'])}
                >
                  <UploadFile fontSize="large" />
                  <span>
                    {t('views.portfolio.expositions.detail.add_files')}
                  </span>
                </Button>
              )}
            </div>
          )}

          {isAddingObjects ? (
            // Adding new objects to exposition
            <div className={cx(styles['new-assignments'])}>
              <h2>{t('views.portfolio.expositions.detail.add_assignments')}</h2>
              <AnswersView
                selectable
                filter={(answer) =>
                  !exposition
                    ?.learningObjects?.()
                    .some((item) => item.id === answer.learningObject?.().id)
                }
              />
            </div>
          ) : addFiles ? (
            // Adding new files to exposition
            <div className={cx(styles['new-assignments'])}>
              <div className={cx(styles['horizontal-split'])}>
                <h2>{t('views.portfolio.expositions.detail.add_files')}</h2>
                <div>
                  <Button type="button" onClick={handleFileUpload}>
                    <span>{t('save')}</span>
                  </Button>
                </div>
              </div>
              <UploadBox
                url={`${baseUrl}/v1/services/temporary_file_upload`}
                headers={{ ...apiHeaders, 'Content-Type': undefined }}
                paramName="file"
                onAfterFileUpload={(file, response) => {
                  if (response?.data?.id) {
                    setFiles((prevFiles) => [
                      ...prevFiles,
                      response?.data?.id as string,
                    ]);
                  }
                }}
                className={styles.uploadButton}
              />
            </div>
          ) : visibleLearningObjects?.length === 0 &&
            visibleStoredFiles.length === 0 ? (
            // Empty state: exposition has no files nor objects
            <Panel pad className={styles.empty}>
              <h4>
                {t('views.portfolio.expositions.detail.no_assignments_found')}
              </h4>
              <p>
                {t(
                  'views.portfolio.expositions.detail.no_assignments_found_instructions'
                )}
              </p>
              <FolderImage />
            </Panel>
          ) : (
            // Filled state: show exposition
            <>
              <div className={styles['filter-bar']}>
                <FilterSidebarButton
                  label={t('students.filter')}
                  count={
                    (courseFilters &&
                      Object.keys(courseFilters).length > 0 &&
                      Object.keys(courseFilters).length) ||
                    undefined
                  }
                  title={t('students.filter_panel.title')}
                  onReset={() => setCourseFilters([])}
                >
                  {courses && (
                    <Accordion
                      quiet
                      open
                      title={t('views.portfolio.filterPanel.courses')}
                      className={cx(styles.accordion)}
                    >
                      {courses.map((course) => (
                        <Checkbox
                          key={course?.id}
                          id={course?.id}
                          quiet
                          checked={courseFilters?.includes(
                            course?.id as string
                          )}
                          onChange={() => handleToggleFilter(course?.id)}
                          name={course?.title}
                        >
                          {course?.title}
                        </Checkbox>
                      ))}
                    </Accordion>
                  )}
                </FilterSidebarButton>
                <Search
                  className={cx(styles.searchInput)}
                  onClose={() => {
                    setSearch('');
                  }}
                  onChange={(event) => setSearch(event.target.value || '')}
                  placeholder={t('views.portfolio.search')}
                  debounce={200}
                />
                <GridListToggle value={isGridView} onChange={setIsGridView} />
              </div>
              {isGridView ? (
                <div className={cx(styles['card-wrapper'])}>
                  {visibleLearningObjects?.map((learningObject) => (
                    <ObjectCard
                      key={learningObject.id}
                      object={learningObject}
                      targetUrl={getLearningObjectUrl(learningObject)}
                      onCheck={
                        isMine
                          ? () => handleToggleSelect([learningObject.id])
                          : undefined
                      }
                      checked={selection.includes(learningObject.id)}
                    />
                  ))}

                  {visibleStoredFiles.map((file) => (
                    <Link
                      href={getStoredFileUrl(file)}
                      key={file.id}
                      className={styles.card}
                    >
                      <LearningCard
                        key={file.id}
                        posterUrl={
                          !isDocument(file.originalFileName)
                            ? file.downloadUrl
                            : undefined
                        }
                        fallbackImage={<FallbackImage />}
                        title={file.title || file.originalFileName}
                        status={{ state: 'NONE', label: '' }}
                        subheader={
                          <Badge>
                            {t(
                              'views.portfolio.expositions.detail.uploaded_file'
                            )}
                          </Badge>
                        }
                        rightOverlay={
                          isMine ? (
                            <StoredFileActionMenu storedFile={file} />
                          ) : undefined
                        }
                      />
                    </Link>
                  ))}
                </div>
              ) : (
                <Panel>
                  <Table>
                    <Table.THead>
                      <Tr>
                        {!profileId && (
                          <TdCheckbox
                            id={visibleLearningObjects.map((item) => item.id)}
                          />
                        )}
                        <Th>
                          {t(
                            'views.portfolio.expositions.detail.table.assignment'
                          )}
                        </Th>
                        <Th>
                          {t('views.portfolio.expositions.detail.table.status')}
                        </Th>
                        <Th>
                          {t(
                            'views.portfolio.expositions.detail.table.handed_in'
                          )}
                        </Th>
                        <Th />
                        <Th />
                      </Tr>
                    </Table.THead>
                    <Table.TBody>
                      {visibleLearningObjects.map((learningObject) => (
                        <LearningObjectRow
                          key={learningObject.id}
                          learningObject={learningObject}
                          learningObjectUrl={getLearningObjectUrl(
                            learningObject
                          )}
                        />
                      ))}
                      {exposition?.storedFiles?.().map((file) => (
                        <Tr key={file.id} href={file.downloadUrl}>
                          {!profileId && <Td />}
                          <Td to={file.downloadUrl}>{file.originalFileName}</Td>
                          <Td />
                          <Td />
                          <Td>
                            <Badge>
                              {t(
                                'views.portfolio.expositions.detail.uploaded_file'
                              )}
                            </Badge>
                          </Td>
                          <Td>
                            <StoredFileActionMenu storedFile={file} />
                          </Td>
                        </Tr>
                      ))}
                    </Table.TBody>
                  </Table>
                </Panel>
              )}
            </>
          )}
        </div>
      )}
      <SelectionMenu open={selection.length > 0}>
        {!isAddingObjects ? (
          <button type="button" onClick={handleRemoveLearningObjects}>
            <DeleteForeverOutlined />
            <span>{t('views.portfolio.exposition.delete')}</span>
          </button>
        ) : (
          <button type="button" onClick={handleAddLearningObjects}>
            <Add fontSize="large" />
            <span>{t('views.portfolio.exposition.append_assignments')}</span>
          </button>
        )}
        <button
          type="button"
          onClick={() => {
            handleToggleSelectAll();
            setIsAddingObjects(false);
          }}
        >
          <span>{t('cancel')}</span>
        </button>
      </SelectionMenu>
    </div>
  );
};
