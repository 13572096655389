import React from 'react';
import { Status } from '@brainstud/ui/Static/Status';
import { useTranslator } from 'Providers/Translator';

type AnswerStatusProps = {
  /** The status of the answer given by the student */
  status: 'TURNED_IN' | 'REJECTED' | 'ACCEPTED';
};

/**
 * Shows an overlay and a status indication of a answer. Typically used
 * in a Card
 */
export const AnswerStatus = ({ status }: AnswerStatusProps) => {
  const [t] = useTranslator();

  return (
    <Status
      scheme={
        { TURNED_IN: 'yellow', REJECTED: 'red', ACCEPTED: 'green' }[status] as
          | 'yellow'
          | 'red'
          | 'green'
      }
    >
      {' '}
      {t(`components.answer_status.status.${status.toLowerCase()}`)}
    </Status>
  );
};
