import React, { FC, PropsWithChildren } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Container } from 'Components/Container';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as MissingPiece } from '../Assets/puzzle.svg';
import styles from './ErrorView.module.css';

type TProps = {
  /** Overwrite the default title */
  title?: string;
  /** Overwrite the default explanation message */
  message?: string;
};

/**
 *  A nice view to show an error
 */
export const ErrorView: FC<PropsWithChildren<TProps>> = ({
  title,
  message,
}) => {
  const [t] = useTranslator();
  return (
    <Container className={styles.base}>
      <Panel>
        <section>
          <article>
            <h2>{title || t('errors.title')}</h2>
            <p>{message || t('errors.description')}</p>
            <Button href="/">{t('errors.return_home')}</Button>
          </article>
          <aside>
            <MissingPiece />
          </aside>
        </section>
      </Panel>
    </Container>
  );
};
